import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
import useImageUpload from '@/services/upload/useImageUpload'
import useAlertNotification from '@/services/notification/useAlert'

export default function useCategorieDocuments() {
  const {
    uploadFile, fileName
} = useImageUpload()
  const { errorToast, successToast } = useAlertNotification()
  const categorieDocument = ref([])
  const loader = ref(false)
  const categorieDocumentProcess = ref(false)
  const categorieDocumentSuccess = ref(false)
  const validation = ref([])
  const categorieDocuments = ref([])
  const errors = ref('')
  const imagelink = ref('')
  const imageReadlink = ref('')
  const c40data = ref('')

  // Liste des type_documents
  const getCategorieDocuments = async () => {
    loader.value = true
    await axiosClient.get('/type_documents').then(response => {
      if (response.data.success === true) {
        loader.value = false
        categorieDocuments.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }

  // Liste des type_documents
  const validate = async (data) => {
    loader.value = true
    errors.value = ''
    categorieDocumentProcess.value = true
    await axiosClient.post(`/validate-public-data/${data.type_document}`, data)
      .then(response => {
        if (response.data.success === true) {
          validation.value = response.data.data
          successToast("Succes",'Les donnees ont bien ete validees')
          generate(data)
        }else{
          const responseErrors = response.data.data
          responseErrors.forEach(error => {
            errorToast('Oups erreur !', '  \n-'+error)
          });
        }
      })
      .catch(error => {
        categorieDocumentProcess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        }
         else {
          console.log(error)
          errors.value = error.response.data.data
          const responseErrors = error.response.data.data
          Object.keys(responseErrors).forEach(key => {
            errorToast('Oups erreur !', responseErrors[key][0])
          })
          errors.value = error.response.data.errors
        }
      })
  }

  // Liste des type_documents
  const generate = async (data) => {
    loader.value = true
    errors.value = ''
    categorieDocumentProcess.value = true
    await axiosClient.post(`/generate-public-cev/${data.type_document}/${data.taille}`, validation)
      .then(async response => {
        if (response.data.success === true) {
          const data = new FormData()
          data.append('c40data', response.data.data.message)
          await uploadFile(data)
          imagelink.value = 'https://e-cev-utility.mameribj.org/api/Cevs/'+fileName.value
          imageReadlink.value = 'https://e-cev-utility.mameribj.org/api/read-cev/'+fileName.value
        }
      })
      .catch(error => {
        categorieDocumentProcess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          console.log(error.response.data.data)
          errors.value = error.response.data.data
          const responseErrors = error.response.data.data
          Object.keys(responseErrors).forEach(key => {
            errorToast('Oups erreur !', responseErrors[key][0])
          })
          errors.value = error.response.data.errors
        }
      })
  }

  // Obtenir un categorieDocument
  const getCategorieDocument = async slug => {
    const response = await axiosClient.get(`/type_documents/${slug}`)
    categorieDocument.value = response.data.data
  }
  // Ajouter une categorieDocument
  const createCategorieDocument = async data => {
    errors.value = ''
    categorieDocumentProcess.value = true
    await axiosClient.post('/type_documents', data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Enregistrement', response.data.message)
          categorieDocumentSuccess.value = true
          categorieDocumentProcess.value = false
          getCategorieDocuments()
        }
      })
      .catch(error => {
        categorieDocumentProcess.value = false
        categorieDocumentSuccess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errors.value = error.response.data.errors
          const responseErrors = error.response.data.errors
          Object.keys(responseErrors).forEach(key => {
            errorToast('Oups erreur !', responseErrors[key][0])
          })
          errors.value = error.response.data.errors
        }
      })
  }

  // Modifier une categorieDocument
  const updateCategorieDocument = async data => {
    errors.value = ''
    categorieDocumentProcess.value = true
    await axiosClient.patch(`/type_documents/${data.slug}`, data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Modification', response.data.message)
          categorieDocumentSuccess.value = true
          categorieDocumentProcess.value = false
          getCategorieDocuments()
        }
      })
      .catch(error => {
        categorieDocumentProcess.value = false
        categorieDocumentSuccess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  return {
    errors,
    categorieDocument,
    categorieDocuments,
    getCategorieDocuments,
    categorieDocumentProcess,
    createCategorieDocument,
    updateCategorieDocument,
    getCategorieDocument,
    loader,
    generate,
    c40data,
    imagelink,
    imageReadlink,
    fileName,
    validation,
    validate,
    categorieDocumentSuccess,
  }
}
