import { inject } from '@vue/composition-api'

export default function useSweetAlertNotification() {
  const swal = inject('$swal')

  const successSweetAlert = (title, text) => {
    swal.fire({
      title,
      text,
      icon: 'success',
      customClass: {
        confirmButton: 'btn btn-primary btn-sm',
      },
      buttonsStyling: false,
    })
  }
  const errorSweetAlert = (title, text) => {
    swal.fire({
      title,
      text,
      icon: 'error',
      customClass: {
        confirmButton: 'btn btn-primary btn-sm',
      },
      buttonsStyling: false,
    })
  }
  return {
    successSweetAlert,
    errorSweetAlert,
  }
}
